/**
 * @flow
 */
import * as React from 'react';
import {api, util} from "../../../../services/service";
import {TabButton} from "../../CommonStyle";
import {PrimaryButton} from "../../../../components/buttons";
import ForwardingBLListSearchFormManager from "../../../../forms/newCoship/forwarding/list/ForwardingBLListSearchFormManager";
import ForwardingBLListContainerSearchFormManager from "../../../../forms/newCoship/forwarding/list/ForwardingBLListContainerSearchFormManager";
import FilterPane from "../../../../components/FilterPane";
import useForwardingHouseBLListGridView from "../../../../grids/newCoship/forwarding/list/useForwardingHouseBLListGridView";
import useForwardingMasterBLListGridView from "../../../../grids/newCoship/forwarding/list/useForwardingMasterBLListGridView";
import useForwardingContainerBLListGridView from "../../../../grids/newCoship/forwarding/list/useForwardingContainerBLListGridView";
import {useTopbar} from "../../../../redux/reducers/topbarReducer";

const TABS = [
  {index: 1, path: '/admin/forwarding/bllist/house'},
  {index: 2, path: '/admin/forwarding/bllist/master'},
  {index: 3, path: '/admin/forwarding/bllist/container'},
];

const searchForm = new ForwardingBLListSearchFormManager();
const containerSearchForm = new ForwardingBLListContainerSearchFormManager();

const ForwardingBLList = () => {
  useTopbar({label: 'Forwarding'}, {label: 'BL List'});
  const [clickedIndex, setClickedIndex] = React.useState<number>(1);
  const pathname = window.location.pathname;
  const {hblGrid, mblGrid, containerGrid} = useForwardingHouseBLList();

  const onSearch = () => {
    const gridInfo = util.getGridInfo(hblGrid.props.name);
    const values = clickedIndex === TABS[2].index ? containerSearchForm.getValues() : searchForm.getValues();
    const fromDate = values.fromDate ? util.toStartTS(values.fromDate) : undefined;
    const toDate = values.toDate ? util.toEndTS(values.toDate, 'day') : undefined;
    const flag = values?.flag?.length > 0;
    const kind = values.kind ? values.kind : undefined;
    const carrierType = values.carrierType ? values.carrierType : undefined;
    const blStatus = values.bl_status ? values.bl_status : undefined;
    let payload;
    if (pathname === TABS[2].path) {
      payload = {...api.getQueryListData(gridInfo), page: 1, ...values, fromDate, toDate, bl_status: blStatus, kind: undefined};
    } else {
      payload = {...api.getQueryListData(gridInfo), page: 1, ...values, fromDate, toDate, flag, kind, carrierType, bl_status: blStatus};
    }
    let search;
    if (pathname === TABS[1].path) {
      search = mblGrid.query(payload);
    } else if (pathname === TABS[2].path) {
      search = containerGrid.query(payload);
    } else {
      search = hblGrid.query(payload);
    }
    return search;
  };

  searchForm.onSearch = onSearch;
  containerSearchForm.onSearch = onSearch;

  const onClear = () => {
    searchForm.clearValues();
  };

  const onClickTab = (tabIndex) => {
    setClickedIndex(tabIndex);
    util.nav(TABS[tabIndex-1].path);
  };

  React.useEffect(() => {
    if (pathname === TABS[1].path) {
      setClickedIndex(TABS[1].index);
    } else if (pathname === TABS[2].path) {
      setClickedIndex(TABS[2].index);
    } else {
      setClickedIndex(TABS[0].index);
    }
    util.nav(pathname);
    searchForm.clickedIndex = clickedIndex;
    containerSearchForm.clickedIndex = clickedIndex;
  }, [clickedIndex, pathname]);

  return (
    <div className={'flex'}>
      <div>
        <FilterPane>
          {clickedIndex !== TABS[2].index && searchForm.renderForm()}
          {clickedIndex === TABS[2].index && containerSearchForm.renderForm()}
          <PrimaryButton label={'APPLY'} onClick={onSearch} noMargin className={'w-full mt-3'} />
          <PrimaryButton label={'CLEAR'} onClick={onClear} noMargin className={'w-full mt-2'} />
        </FilterPane>
      </div>
      <div className={'flex-1 ml-24'} style={{position: 'relative'}}>
        <div className={'flex'} style={{marginLeft: -12}}>
          <TabButton style={{backgroundColor: clickedIndex === TABS[0].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[0].index)}>
            <div>HOUSE BL</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[1].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[1].index)}>
            <div>MASTER BL</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[2].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[2].index)}>
            <div>CONTAINER</div>
          </TabButton>
        </div>
        <div style={{marginTop: -50}}>
          {clickedIndex === TABS[0].index && hblGrid.render()}
          {clickedIndex === TABS[1].index && mblGrid.render()}
          {clickedIndex === TABS[2].index && containerGrid.render()}
        </div>
      </div>
    </div>
  );
};

function useForwardingHouseBLList() {
  const hblGrid = useForwardingHouseBLListGridView((action, data) => {
    if (action === 'edit') {
      util.nav(`/admin/forwarding/house/customer?id=${data.id}`);
    }
  });
  const mblGrid = useForwardingMasterBLListGridView((action, data) => {
    if (action === 'edit') {
      util.nav(`/admin/forwarding/master/mbl?id=${data.id}`);
    }
  });
  const containerGrid = useForwardingContainerBLListGridView((action, data) => {
    if (action === 'edit') {
      util.openTab(`/admin/forwarding/master/container?id=${data.id}`);
    }
  });
  return {hblGrid, mblGrid, containerGrid};
}

export default ForwardingBLList;
