/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {useSelector} from "react-redux";
import {FD, FN} from "../../../field-defs/newCoship";
import {PortSearchInput, VslVoySearchInput} from "../../../../components/SearchInput";
import {util} from "../../../../services/service";
import {setCBM, setPKG} from "../../../../redux/reducers/newCoshipReducer";

class ForwardingHouseBLWorkDetailsFormManager extends FormManager {
  isShowForm;
  constructor() {
    super({
      prefix: `forwarding-house-work_details-form`,
      fields: [
        FD.REMARK, FD.REMARK_D, FD.CBM, FD.OFFER_CBM, FD.ONBOARD, FD.VSL_VOY, FD.POL, FD.INSURANCE, FD.CONTAINER_SEQ, FD.ETD,
        FD.WAREHOUSE_ARRIVAL_DATE, FD.CLEARED_BY_DATE, FD.POD, FD.ETA, FD.FLIGHT_NO, FD.SHIPMODE, FD.COMMODITY,
        FD.PACKAGE, FD.GROSS_WEIGHT_LB, FD.GROSS_WEIGHT_KG, FD.CHARGEABLE_WEIGHT_KG, FD.DEPARTURE, FD.DEPARTURE_FLIGHT,
        FD.DESTINATION, FD.DESTINATION_FLIGHT, FD.FLIGHT_DATE, FD.FLIGHT_TIME, FD.ARRIVAL_DATE, FD.ARRIVAL_TIME,
        {name: 'containerNo', serverName: 'containerNo', label: 'CNTR NO.', smallMargin: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const {carrierType} = useSelector(state => state['newCoship']);
    const [formik, , fields, errors] = this.renderParams;
    _f(FN.COMMODITY).type = 'text';
    _f(FN.REMARK).label = '';
    _f(FN.CBM).onChange = (_, value) => setCBM(util.dispatch, value);
    _f(FN.PACKAGE).onChange = (_, value) => setPKG(util.dispatch, value ? util.toInt(value) : '');
    _f(FN.VSL_VOY).disabled = true;
    _f(FN.CONTAINER_SEQ).disabled = true;
    _f('containerNo').disabled = true;
    _f(FN.POL).disabled = true;
    _f(FN.POD).disabled = true;
    _f(FN.ETD).disabled = true;
    _f(FN.ETA).disabled = true;
    _f(FN.FLIGHT_NO).disabled = true;
    _f(FN.DEPARTURE).disabled = true;
    _f(FN.DEPARTURE_FLIGHT).disabled = true;
    _f(FN.FLIGHT_DATE).disabled = true;
    _f(FN.FLIGHT_TIME).disabled = true;
    _f(FN.DESTINATION).disabled = true;
    _f(FN.DESTINATION_FLIGHT).disabled = true;
    _f(FN.ARRIVAL_DATE).disabled = true;
    _f(FN.ARRIVAL_TIME).disabled = true;

    // 자동 무게 계산
    if (carrierType !== 'A') {
      const lb = 2.20462;
      const gwkg = _v(FN.GROSS_WEIGHT_KG);
      const gwlb = _v(FN.GROSS_WEIGHT_LB);
      _f(FN.GROSS_WEIGHT_KG).onBlur = () => {
        if (gwkg) {
          _c(FN.GROSS_WEIGHT_LB, (gwkg * lb).toFixed(2));
        }
      }
      _f(FN.GROSS_WEIGHT_LB).onBlur = () => {
        if (!gwkg && gwlb) {
          _c(FN.GROSS_WEIGHT_KG, (gwlb / lb).toFixed(2));
        }
      }
    }

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-20 pr-20 pt-12 pb-1'}>
            <div className={'flex w-full'}>
              <div className={'flex-1 mr-4'}>
                <div>REMARK(ORIGIN)</div>
                <div>{_r(FN.REMARK)}</div>
              </div>
              <div className={'flex-1'}>
                <div>REMARK(DESTINATION)</div>
                <div>{_r(FN.REMARK_D)}</div>
              </div>
            </div>
            {carrierType === 'O' && <OCEAN _r={_r}/>}
            {carrierType === 'A' && <AIR _r={_r}/>}
            {carrierType === 'G' && <GROUND _r={_r}/>}
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

const OCEAN = (props) => {
  const {_r} = props;
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.CBM)}</div>
        <div className={'flex-1'}>{_r(FN.ONBOARD)}</div>
        <div className={'flex-1'}>{_r(FN.VSL_VOY)}</div>
        <div className={'flex-1'}>{_r(FN.POL)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.SHIPMODE)}</div>
        <div className={'flex-1'}>{_r(FN.INSURANCE)}</div>
        <div className={'flex-1'}>{_r(FN.CONTAINER_SEQ)}</div>
        <div className={'flex-1'}>{_r(FN.ETD)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.WAREHOUSE_ARRIVAL_DATE)}</div>
        <div className={'flex-1'}>{_r(FN.CLEARED_BY_DATE)}</div>
        <div className={'flex-1'}>{_r('containerNo')}</div>
        <div className={'flex-1'}>{_r(FN.POD)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.COMMODITY)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex'}>{_r(FN.GROSS_WEIGHT_KG)} <span style={{marginTop: 5, marginLeft: 5}}>KG</span></div>
          <div className={'flex ml-2'}>{_r(FN.GROSS_WEIGHT_LB)} <span style={{marginTop: 5, marginLeft: 5}}>LB</span></div>
        </div>
        <div className={'flex-1'}>{_r(FN.PACKAGE)}</div>
        <div className={'flex-1'}>{_r(FN.ETA)}</div>
      </div>
    </>
  );
};

const AIR = (props) => {
  const {_r} = props;
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.CBM)}</div>
        <div className={'flex-1'}>{_r(FN.SHIPMODE)}</div>
        <div className={'flex-1'}>{_r(FN.FLIGHT_NO)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.DEPARTURE)}</div>
          <div className={'flex-1'}>{_r(FN.DEPARTURE_FLIGHT)}</div>
        </div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.OFFER_CBM)}</div>
        <div className={'flex-1'}>{_r(FN.INSURANCE)}</div>
        <div className={'flex-1'}>{_r(FN.WAREHOUSE_ARRIVAL_DATE)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.FLIGHT_DATE)}</div>
          <div className={'flex-1'}>{_r(FN.FLIGHT_TIME)}</div>
        </div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex flex-1'}>{_r(FN.GROSS_WEIGHT_KG)} <span style={{marginTop: 15, marginLeft: 5}}>KG</span></div>
        <div className={'flex-1'}>{_r(FN.CHARGEABLE_WEIGHT_KG)}</div>
        <div className={'flex-1'}>{_r(FN.CLEARED_BY_DATE)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.DESTINATION)}</div>
          <div className={'flex-1'}>{_r(FN.DESTINATION_FLIGHT)}</div>
        </div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-2'}>{_r(FN.COMMODITY)}</div>
        <div className={'flex-1'}>{_r(FN.PACKAGE)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex-3 mr-1'}>{_r(FN.ARRIVAL_DATE)}</div>
          <div className={'flex-1'}>{_r(FN.ARRIVAL_TIME)}</div>
        </div>
      </div>
    </>
  );
};

const GROUND = (props) => {
  const {_r} = props;
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.CBM)}</div>
        <div className={'flex-1'}>{_r(FN.OFFER_CBM)}</div>
        <div className={'flex-1'}>{_r(FN.POL)}</div>
        <div className={'flex-1'}>{_r(FN.POD)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1'}>{_r(FN.SHIPMODE)}</div>
        <div className={'flex-1'}>{_r(FN.INSURANCE)}</div>
        <div className={'flex-1'}>{_r(FN.ETD)}</div>
        <div className={'flex-1'}>{_r(FN.ETA)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-2'}>{_r(FN.COMMODITY)}</div>
        <div className={'flex flex-1'}>
          <div className={'flex'}>{_r(FN.GROSS_WEIGHT_KG)} <span style={{marginTop: 5, marginLeft: 5}}>KG</span></div>
          <div className={'flex ml-2'}>{_r(FN.GROSS_WEIGHT_LB)} <span style={{marginTop: 5, marginLeft: 5}}>LB</span></div>
        </div>
        <div className={'flex-1'}>{_r(FN.PACKAGE)}</div>
      </div>
    </>
  );
};

export default ForwardingHouseBLWorkDetailsFormManager;
