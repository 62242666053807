/**
 * @flow
 */
import React from 'react';
import {apiBL, util} from '../../services/service';
import {useSimpleGridModal} from '../../components/SimpleModal';
import {ColDef, GridApi} from 'ag-grid-community';

export function useDayScheduleModal() {
  const [scheduleDataList, setScheduleDataList] = React.useState();
  const [savedScheduleListIds, setSavedScheduleListIds] = React.useState();
  const [selectedDate, setSelectedDate] = React.useState();
  const [printModal, setPrintModal] = React.useState();
  const [dayScheduleAddModal, setDayScheduleAddModal] = React.useState();
  const rowsRef = React.useRef([]);
  const modalRef = React.useRef();

  function onAdd() {
    dayScheduleAddModal.open(modal, savedScheduleListIds, selectedDate, rowsRef);
  }
  function onSave() {
    const selectedRows = modal.grid.apiRef.current.getSelectedRows();
    const payload = {
      assignList: selectedRows.map((i, index) => ({...i, id: index})),
      workDate: util.formatD(selectedDate),
    };
    apiBL.setDailyAssign(payload).then(res => {
      if (res) {
        util.showSuccess('Day Schedule has been saved successfully!');
      }
    });
  }
  function onGoogleMap() {
    // let selectedRows = [];
    // modal.grid.apiRef.current.forEachNode(node => selectedRows.push(node.data));
    const selectedRows = modal.grid.apiRef.current.getSelectedRows();
    if (selectedRows.length <= 0) {
      util.showWarning('Please select one or more rows.');
      return;
    }
    const addresses = selectedRows.map(row => encodeURI(row['address'] ? row['address'] : row['city']));
    const sAddr = `saddr=${addresses[0]}`;
    let dAddr = '';
    if (addresses.length > 1) {
      dAddr = `daddr=${addresses[1]}`;
      if (addresses.length > 2) {
        for (let i = 2; i < addresses.length; ++i) {
          dAddr += `+to:${addresses[i]}`;
        }
      }
    }
    const url = `http://maps.google.com/maps?${sAddr}&${dAddr}`;
    window.open(url, '_blank', 'toolbar=0,location=0,menubar=0');
  }
  function onDownload() {
    if (modal.grid.rows?.length > 0) {
      const api: GridApi = modal.grid.apiRef.current;
      const workDate = modal.grid.rows[0].work_date;
      api.exportDataAsCsv({
        fileName: `work-assigned-${workDate}.csv`,
        columnKeys: getColumns().filter((c, index) => ![0, 8, 9].includes(index)).map(c => c.field),
      });
    }
  }
  function onPrint() {
    const selectedRows = modal.grid.apiRef.current.getSelectedRows();
    printModal.open(selectedRows, selectedDate);
  }
  function getButtons() {
    return [
      {label: 'Add', color: 'primary', onClick: onAdd},
      {label: 'Save', color: 'primary', onClick: onSave},
      {label: 'Google Map', onClick: onGoogleMap},
      {label: 'Export CSV', onClick: onDownload},
      {label: 'Print', onClick: onPrint},
    ];
  }
  function getColumns() {
    const columns: ColDef[] = [
      {field: 'check', headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 40, minWidth: 40},
      {field: 'kind', headerName: 'S.Type', width: 40, minWidth: 40},
      {field: 'work_type', headerName: 'W.Type', width: 45, minWidth: 45},
      {field: 'carrier_type', headerName: 'Carrier', width: 50, minWidth: 50},
      {field: 'workplace', headerName: 'Customer', width: 100},
      {field: 'address', headerName: 'Address', rowDrag: true, flex: 1},
      {field: 'city', headerName: 'City', width: 70, minWidth: 70},
      {field: 'pack_count', headerName: 'Packages', rowDrag: true, width: 80, minWidth: 80},
      {field: 'work_time', headerName: 'Time', valueFormatter: util.uppercaseFormatter, width: 80, minWidth: 80, editable: true},
      {field: 'dropzone', headerName: 'D.Zone', width: 70, minWidth: 70},
      {field: 'phone', headerName: 'Phone', width: 80},
      {field: 'remark', headerName: 'Remark', editable: true, flex: 1},
      {field: 'cbm_tape', headerName: 'CBM/Tape', width: 80},
      {field: 'amount', headerName: 'Amount', valueFormatter: util.currencyFormatter, width: 80},
      {field: 'balance', headerName: 'Balance', valueFormatter: util.currencyFormatter, width: 80},
      {field: 'id', hide: true},
      {field: 'tape', headerName: 'Tape', hide: true},
      {field: 'cbm', headerName: 'CBM', hide: true},
      {field: 'cbm_offer', headerName: 'Offer CBM', hide: true},
      {field: 'bl_no', headerName: 'BL No.', hide: true},
    ];
    return columns;
  }
  const modal = useSimpleGridModal({
    title: `DAY SCHEDULE (${util.formatD(selectedDate)})`,
    centered: true,
    width: 1400,
    buttons: getButtons(),
    gridProps: {
      columns: getColumns(),
      actions: [],
      actionWidth: 50,
      isActionDisabled: (type, data) => {
        // return type === 'delete' && modalRef.current.grid.rows?.length <= 1;
        return type === 'delete' && modal.grid.rows?.length <= 1;
      },
      onAction: (action, data) => {
        if (action === 'delete') {
          // const grid = modalRef.current.grid
          const grid = modal.grid
          const curRows = grid.rows ?? [];
          if (curRows.length > 1) {
            util.showConfirm('Are you sure to delete?', () => {
              const filteredRows = curRows.filter(i => i.id !== data.id);
              grid.setRows(filteredRows);
              rowsRef.current = filteredRows;
            });
          }
        }
      },
      rows: [],
      height: 600,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        rowMultiSelectWithClick: true,
        rowDragManaged: true,
        animateRows: true,
        onRowClicked(e) {},
        onRowDragEnd(e) {
          // const rows = rowsRef.current;
          const rows = modal.grid.rows;
          const index = e.node.data['index'];
          const newIndex = e.node.rowIndex;
          const sourceIndex = rows.findIndex(i => i.index === index);
          const sourceRow = rows[sourceIndex];
          rows.splice(sourceIndex, 1);
          rows.splice(newIndex, 0, sourceRow);
          rowsRef.current = [...rows];
          modal.grid.apiRef.current.setRowData(rowsRef.current);
          if (rowsRef.current?.length > 0) {
            setTimeout(() => {
              modal.grid.apiRef.current.forEachNode((node) => node.setSelected(savedScheduleListIds?.includes(node.data.workplace_id)));
            }, 0);
          }
        },
      }
    },
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
      apiBL.getDailyAssign({workDate: util.formatD(selectedDate)}).then((res) => {
        if (res) {
          const savedScheduleList = res?.data[0]?.assign_list ?? [];
          const savedScheduleListIds = savedScheduleList?.map(data => data.workplace_id) ?? [];
          const exceptSavedScheduleList = scheduleDataList.filter(x => !savedScheduleListIds?.includes(x.workplace_id)) ?? [];
          const scheduleData = [...savedScheduleList, ...exceptSavedScheduleList];
          const newScheduleData = scheduleData.map((data, index) => ({index, ...data}));
          setSavedScheduleListIds(savedScheduleListIds);
          modal.setRows(newScheduleData);
          rowsRef.current = newScheduleData;
          if (newScheduleData?.length > 0) {
            setTimeout(() => {
              modal.grid.apiRef.current.forEachNode((node) => node.setSelected(savedScheduleListIds?.includes(node.data.workplace_id)));
            }, 0);
          }
        }
      });
    }
  }, [modal.isOpen]);
  const open = (data, day, daySchedulePrintModal, dayScheduleAddModal) => {
    setScheduleDataList(data);
    setSelectedDate(day);
    setPrintModal(daySchedulePrintModal);
    setDayScheduleAddModal(dayScheduleAddModal);
    modal.open();
  };
  modalRef.current = modal;
  return {...modal, open};
}
