/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../components/SimpleModal';
import {useReactToPrint} from 'react-to-print';
import logoTop from '../media/logo_top.png';
import logoBottom from '../media/logo_bottom.png';

const CourierOceanContractPrint = React.forwardRef((props, ref) => {
  const {printData} = props;
  if (printData?.length === 0) {
    return null;
  }

  const renderHeader = () => {
    return (
      <div className={'flex'} style={{height: '6%', backgroundColor: '#ed1745', color: 'white', fontWeight: 'bold'}}>
        <div className={'flex flex-2'} style={{justifyContent: 'center', alignItems: 'center', marginLeft: 20}}>
          <div><img src={logoTop}/></div>
          <div style={{marginLeft: 10, fontSize: 18}}>{'-보관용-'}</div>
        </div>
        <div className={'flex-3'} style={{justifyContent: 'center', alignContent: 'center', fontSize: 36}}>{'MAPLE BOX(BAG) 계약서'}</div>
        <div className={'flex-2'} style={{justifyContent: 'center', alignItems: 'center', marginRight: 20}}>
          <div className={'flex'} style={{fontSize: 20}}>
            <div className={'flex-2'}/>
            <div className={'flex-3'}>{'www.coship.ca'}</div>
          </div>
          <div className={'flex'} style={{fontSize: 24}}>
            <div className={'flex-2'}>{'Track No.'}</div>
            <div className={'flex-3'} style={{backgroundColor: 'white', color: 'black'}}>{'12345'}</div>
          </div>
        </div>
      </div>
    );
  }

  const renderBody = () => {
    return (
      <div style={{height: '90%', paddingTop:10, paddingLeft: 80, paddingRight: 80, paddingBottom: 20, color: '#ed1745', fontSize: 28}}>
        {/*1. 고객정보*/}
        <div>
          <div>1. 고객정보</div>
          <div className={'flex'} style={{marginTop: 15}}>
            <div className={'flex-1'} style={{paddingRight: 10}}>
              <div style={{height: 410, border: '1px solid black', borderRadius: 25}}>
                <div style={{position: 'absolute', width: 300, height: 43, top: 143, left: 170, zIndex: 10, backgroundColor: '#ed1745', color: 'white', textAlign: 'center', borderRadius: 10}}>출발지 정보</div>
                <table className={'print-table'} style={{width: '95%', marginTop: 30, fontSize: 18, color: 'black'}}>
                  <tbody>
                  {renderInput('계약자명:', '(한글)')}
                  {renderInput('(보내시는 분)', '(영문)', {justifyContent: 'flex-start', alignContent: 'flex-start'})}
                  {renderInput('출발지 주소:', '')}
                  {renderInput('', '')}
                  {renderInput('연락처:', '')}
                  {renderInput('추가 연락처:', '')}
                  {renderInput('E-MAIL:', '')}
                  <tr><td colSpan={2} className={'no-border-all'} style={{paddingTop: 10, fontSize: 14}}>{'(구비서류에 대한 안내를 받기 위해서는 확실한 E-MAIL 주소가 필요합니다.)'}</td></tr>
                  {renderInput('귀국일:', '')}
                  {renderInput('비자종류 & 체류기간:', '', {fontSize: 16})}
                  <tr><td colSpan={2} className={'no-border-all'} style={{paddingTop: 10, fontSize: 14}}>{'(귀국일 변경으로 인한 추가 경비는 화주 부담)'}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={'flex-1'} style={{paddingLeft: 10}}>
              <div style={{height: 230, border: '1px solid black', borderRadius: 25}}>
                <div style={{position: 'absolute', width: 300, height: 43, top: 143, left: 660, zIndex: 10, backgroundColor: '#ed1745', color: 'white', textAlign: 'center', borderRadius: 10}}>도착지 정보</div>
                <table className={'print-table'} style={{width: '95%', marginTop: 30, fontSize: 18, color: 'black'}}>
                  <tbody>
                  {renderInput('받으시는 분:', '')}
                  {renderInput('배송지 주소:', '')}
                  {renderInput('(도로명 주소)', '', {justifyContent: 'flex-start', alignContent: 'flex-start'})}
                  {renderInput('연락처:', '')}
                  {renderInput('추가 연락처:', '')}
                  </tbody>
                </table>
              </div>
              <div>
                <div style={{marginTop: 10}}>※서비스 이용시 유의사항</div>
                <div style={{whiteSpace: 'pre-wrap', color: 'black', fontSize: 15, marginLeft: 20}}>
{`1. 해외에 3개월 이상 체류하셨고, 한국 귀국이 확정되신 이사자로 제한됩니다.
2. 대리 계약시 반드시 도착국가에 들어가시는 고객명의로 진행하셔야 합니다.
(받으시는 분이 계약자명이 되어야 함)
3. 비자 종류와 체류기간 (년 / 개월) 을 정확히 기재해주세요.`}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*2. 준비서류 & 3. 서비스 종류*/}
        <div style={{marginTop: 10}}>
          <div className={'flex'}>
            <div className={'flex-1'} style={{paddingRight: 10}}>
              <div>
                <div>2. 준비서류</div>
                <div style={{marginLeft: 20, fontSize: 18, color: 'black'}}>{'•여권사본, 물품 리스트, 출입국 사실 증명서(한국)'}</div>
                <div style={{marginLeft: 20, fontSize: 16, color: 'black'}}>{'(여권 유효기간 만료 시 유효기간 연장 페이지까지 보내주셔야 합니다.)'}</div>
              </div>
              <div style={{marginTop: 10}}>
                <div>3. 서비스 종류</div>
                <div className={'flex'} style={{marginLeft: 20, fontSize: 18, color: 'black'}}>
                  <div><input type={'checkbox'}/> 20Kg</div>
                  <div style={{marginLeft: 30}}><input type={'checkbox'}/> 35Kg</div>
                </div>
                <div style={{marginLeft: 20, fontSize: 16, color: 'black'}}>
                  <span style={{fontSize: 20, fontWeight: 'bold'}}>{'DOOR TO DOOR'}</span><span style={{marginLeft: 10}}>{'(도착지 집까지 배송 서비스)'}</span>
                </div>
              </div>
            </div>
            <div className={'flex-1'} style={{paddingLeft: 10}}>
              <div>
                <div>※COSHIP 연락처</div>
                <div style={{whiteSpace: 'pre-wrap', color: 'black', fontSize: 15, marginLeft: 20}}>
{`TEL : 604-455-1000 / FAX : 1-866-319-8529(캐나다 본사)
TEL : 02-2043-5000(한국지사)
E-MAIL : exp@coship.ca
홈페이지 : www.coship.ca
카카오톡 채널: 캐나다쉬핑`}
                </div>
              </div>
            </div>
          </div>
          <div style={{marginLeft: 20, fontSize: 16, color: 'black'}}>{'※ 계약서 상의 주소가 실제 배달지와 상이한 경우 운송비의 변동이 있을 수 있습니다.'}</div>
        </div>
        {/*4. QUOTATION*/}
        <div style={{marginTop: 10}}>
          <div>
            <div>4. QUOTATION</div>
          </div>
        </div>
        {/*5. INVOICE & PACKING LIST*/}
        <div style={{marginTop: 10}}>
          <div>
            <div>5. INVOICE & PACKING LIST</div>
          </div>
        </div>
      </div>
    );
  }

  const renderFooter = () => {
    return (
      <div className={'flex'} style={{justifyContent: 'center', alignContent: 'center', height: '4%', backgroundColor: '#ed1745', color: 'white', fontSize: 18, fontWeight: 'bold'}}>
        <div style={{alignContent: 'center', marginRight: 15}}><img src={logoBottom}/></div>
        <div style={{justifyContent: 'center', alignContent: 'center'}}>{'캐나다본사: 604-455-1000 / 한국지사: +82-2-2043-5000 / 토론토지사: 647-380-1000'}</div>
      </div>
    );
  }

  const renderInput = (name, value, nameStyle = {}) => {
    return (
      <tr>
        <td className={'no-border-all left'} width={'35%'} height={'35px'} style={{justifyContent: 'flex-end', alignContent: 'flex-end', ...nameStyle}}>{name}</td>
        <td className={'no-border-t no-border-lr left'} width={'65%'} height={'35px'}>{value}</td>
      </tr>
    );
  };

  return (
    <div ref={ref}>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </div>
  );
});

export function useCourierOceanContractPreviewModal() {
  const [printData, setPrintData] = React.useState();
  const printRef = React.useRef();
  const print = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body-no-margin',
  });
  const modal = useSimpleModal({
    title: 'Ocean Contract',
    width: 1200,
    children: <CourierOceanContractPrint ref={printRef} printData={printData}/>,
  });
  React.useEffect(() => {
    setTimeout(() => {
      print();
    }, 500);
  }, [modal.isOpen]);
  return {
    ...modal,
    open: (data) => {
      setPrintData(data);
      modal.open();
    },
    print,
  };
}
