/**
 * @flow
 */
import React from "react";
import FormManager from "../../../../lib/FormManager";
import {FN} from "../../../field-defs/newCoship";
import {apiCourier} from "../../../../services/newCoship/service";

export const FILING_NO = 'filingNo';
export const BL_NO = 'blNo';
export const CONTAINER_NO = 'containerNo';

export default class CourierCLPSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  clickedIndex;
  constructor() {
    super({
      prefix: 'courier-clp-search',
      fields: [
        {name: FILING_NO, serverName: FILING_NO, label: 'Filing No.', smallMargin: true},
        {name: BL_NO, serverName: BL_NO, label: 'MBL No.', smallMargin: true},
        {name: CONTAINER_NO, serverName: CONTAINER_NO, label: 'Container No.', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [masterOptions, setMasterOptions] = React.useState([]);
    _f(FILING_NO).onEnter = _ => this.onSearch();
    _f(CONTAINER_NO).onEnter = _ => this.onSearch();
    _f(BL_NO).options = masterOptions;
    _f(BL_NO).onChange = (_, value) => onChangeMblNo(value);

    const onChangeMblNo = (value) => {
      _c(BL_NO, value);
      setTimeout(() => this.onSearch(), 100);
    };

    const getMasterOptions = () => {
      apiCourier.getComingMBL().then((res) => {
        if (res) {
          const list = res.data ?? [];
          const options = list?.map((data) => ({value: data.bl_no, label: data.bl_no, filingNo: data.filing_no}));
          setMasterOptions(options);
        }
      });
    };

    React.useEffect(() => {
      getMasterOptions();
    }, []);

    return (
      <>
        {_r(FILING_NO)}
        {_r(BL_NO)}
        {_r(CONTAINER_NO)}
      </>
    );
  };
}
