/**
 * @flow
 */
import {useDataGridView} from '../../../../components/DataGridView';
import {util} from '../../../../services/service';
import {apiForwarding} from "../../../../services/newCoship/service";
import React from "react";

export default function useForwardingHouseBLListGridView(onActionCb) {
  return useDataGridView({
    async onAction(action, data) {
      await onActionCb(action, data);
    },
    async onQuery(gridInfo) {
      return apiForwarding.getHBlList(gridInfo);
    },
    name: 'forwardingHouseBLList',
    label: 'forwarding',
    addLabel: '',
    editLabel: 'EDIT HOUSE BL',
    categoryLabel: {label: 'Forwarding'},
    menuLabel: {label: 'BL List'},
    sortCol: '',
    sortDesc: true,
    pageRows: 20,
    actions: ['edit'],
    actionWidth: 70,
    showDateSearch: false,
    showRowSearch: true,
    hideSearchInput: true,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    doNotPerformInitialQuery: true,
    columns: [
      {field: 'carrier_type', headerName: 'Carrier', width: 70},
      {field: 'bound', headerName: 'Bound', width: 70},
      {field: 'shipper', headerName: 'Shipper', flex: 1},
      {field: 'consignee', headerName: 'Consignee', flex: 1},
      {field: 'bl_no', headerName: 'BL#', cellRendererFramework: blLinkFormatter, width: 140},
      {field: 'cdate', headerName: 'C.Date', valueFormatter: util.dateTimeFormatter, width: 170},
      {field: 'bl_status', headerName: 'Status', width: 100},
    ],
  });
};

export function blLinkFormatter(param) {
  const onClick = (e) => {
    e.preventDefault();
    util.nav(`/admin/forwarding/house/customer?id=${param.data.id}`);
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={onClick}>
      {param.value}
    </a>
  );
}
