/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {api, util} from '../../../../services/service';
import {setCarrierType, setKind} from "../../../../redux/reducers/newCoshipReducer";
import {FD, FN} from '../../../field-defs/newCoship';
import {useSelector} from "react-redux";

class CourierMasterBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `courier-master-form`,
      fields: [
        FD.MBL_NO,
        FD.MAWB_NO,
        FD.BOOKING_NO,
        {...FD.PORT_TYPE, defaultValue: 'A'},
        FD.BRANCH_ID,
        {...FD.KIND, defaultValue: 'C'},
        FD.TRADE_TYPE,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const {kind, carrierType, } = useSelector(state => state['newCoship']);
    _f(FN.BRANCH_ID).options = api.useAreaOptions(true);
    _f(FN.PORT_TYPE).onChange = (_, value) => onChangeCarrierType(value);

    const onChangeCarrierType = (value) => {
      _c(FN.PORT_TYPE, value);
      setCarrierType(util.dispatch, value);
    };

    React.useEffect(() => {
      if (kind) {
        _c(FN.KIND, kind);
        setKind(util.dispatch, kind);
      } else {
        _c(FN.KIND, 'C');
        setKind(util.dispatch, 'C');
      }
    }, [kind]);

    React.useEffect(() => {
      if (carrierType) {
        _c(FN.PORT_TYPE, carrierType);
        setCarrierType(util.dispatch, carrierType);
      } else {
        _c(FN.PORT_TYPE, 'A');
        setCarrierType(util.dispatch, 'A');
      }
    }, [carrierType]);

    return(
      <div style={{width: 'calc(100% + 8px)', marginTop: 30}}>
        <div className={'flex w-full'}>
          {carrierType !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {carrierType === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MAWB_NO)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.BOOKING_NO)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{''}</div>
          <div className={'flex-1 mr-2'}>{''}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default CourierMasterBLFormManager;
