/**
 * @flow
 */
import React from "react";
import FormManager from "../../../../lib/FormManager";
import {api, util} from "../../../../services/service";
import {oceanAirOptionsForBLList} from "../../../helper";
import {kindOptions} from "../../../field-defs/bl";
import Icon from "mdi-react/SearchIcon";
import {blStatusOptions} from "../../../field-defs/newCoship";

export const SEARCH = 'qryText';
export const FORWARDING_FROM_DATE = 'fromDate';
export const FORWARDING_TO_DATE = 'toDate';
export const FORWARDING_KIND = 'kind';
export const FORWARDING_BRANCH = 'branchId';
export const FORWARDING_CARRIER_TYPE = 'carrierType';
export const FORWARDING_BL_STATUS = 'bl_status';
export const FORWARDING_FLAG = 'flag';

export default class ForwardingBLListSearchFormManager extends FormManager {
  onSearch: () => void;
  clickedIndex;
  constructor() {
    super({
      prefix: 'forwarding-bl-list-search',
      fields: [
        {name: SEARCH, serverName: SEARCH, label: 'Search', smallMargin: true},
        {name: FORWARDING_FROM_DATE, serverName: FORWARDING_FROM_DATE, label: 'Date', smallLabel: true, smallMargin: true, type: 'date'},
        {name: FORWARDING_TO_DATE, serverName: FORWARDING_TO_DATE, label: '', smallLabel: true, smallMargin: true, type: 'date'},
        {name: FORWARDING_KIND, serverName: FORWARDING_KIND, label: 'Service', options: [{value: '', label: 'ALL'}, ...kindOptions], noDefOption: true, smallMargin: true},
        {name: FORWARDING_BRANCH, serverName: FORWARDING_BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: FORWARDING_CARRIER_TYPE, serverName: FORWARDING_CARRIER_TYPE, label: 'Carrier Type', options: [{value: '', label: 'ALL'}, ...oceanAirOptionsForBLList], noDefOption: true, smallMargin: true},
        {name: FORWARDING_BL_STATUS, serverName: FORWARDING_BL_STATUS, label: 'B/L Status', options: [{value: '', label: 'ALL'}, ...blStatusOptions], noDefOption: true, smallMargin: true},
        {name: FORWARDING_FLAG, serverName: FORWARDING_FLAG, type: 'checkbox', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const areaOptions = api.useAreaOptions(true);
    _f(FORWARDING_BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SEARCH).onEnter = this.onSearch;

    const kind = _v(FORWARDING_KIND);
    const branchId = _v(FORWARDING_BRANCH);
    const carrierType = _v(FORWARDING_CARRIER_TYPE);
    const blStatus = _v(FORWARDING_BL_STATUS);

    const onClickSearch = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onSearch();
    };

    const _sh = () => {
      return (
        <div className={`flex middle`}>
          {_r(SEARCH)}
          <div className={'ml-8 mt-16'}>
            <a href={'/#search'} onClick={onClickSearch}>
              <Icon size={20} />
            </a>
          </div>
        </div>
      );
    };

    React.useEffect(() => {
      const fromDate = util.toDefaultFirstTS(util.getCurrentDate());
      const toDate = util.toDefaultEndTS(util.getCurrentDate());
      _c(FORWARDING_FROM_DATE, fromDate);
      _c(FORWARDING_TO_DATE, toDate);
      _c(FORWARDING_KIND, 'F');
    }, []);

    React.useEffect(() => {
      if (!(kind === undefined && branchId === undefined && carrierType === undefined && blStatus === undefined)) {
        this.onSearch();
      }
    }, [kind, branchId, carrierType, blStatus, this.clickedIndex]);

    return (
      <>
        {_sh()}
        {_r(FORWARDING_FROM_DATE)}
        <div>~</div>
        {_r(FORWARDING_TO_DATE)}
        {_r(FORWARDING_KIND)}
        {_r(FORWARDING_BRANCH)}
        {_r(FORWARDING_CARRIER_TYPE)}
        {_r(FORWARDING_BL_STATUS)}
        <div className={'flex mt-1'}>
          <span className={'mr-2'}>Flag</span>{_r(FORWARDING_FLAG)}
        </div>
      </>
    );
  };
}
