/**
 * @flow
 */
import React from 'react';
import FormManager from "../../../../lib/FormManager";
import {api, util} from '../../../../services/service';
import {AccountTypeSearchInput, TradePartnerSearchInput} from '../../../../components/SearchInput';
import {useSimpleGrid} from '../../../../components/SimpleGrid';
import {DefaultButton} from '../../../../components/buttons';
import {apiForwarding} from "../../../../services/newCoship/service";
import {ColDef} from "ag-grid-community";
import {FD, FN, unitOptions} from "../../../field-defs/newCoship";
import {accTypeOptions, currencyOptions, tradeTypeOptions} from "../../../helper";
import {useUser} from "../../../../redux/reducers/userReducer";

class ForwardingSettingAccountTypeFormManager extends FormManager {
  id;
  data;
  items;
  grid;
  constructor() {
    super({
      prefix: `forwarding-setting-account-type-add-item-form`,
      fields: [
        FD.SUBJECT, FD.PARTNER_ID, FD.PARTNER_NAME,
        {...FD.KIND, defaultValue: 'M'}, {...FD.ACC_TYPE, defaultValue: 'AR'}, {...FD.CURRENCY, defaultValue: 'CAD'},
        {name: 'branch_id', serverName: 'branch_id', label: 'Branch', noDefOption: true, smallMargin: true},
        {name: 'bound', serverName: 'bound', label: 'Bound', options: tradeTypeOptions, defaultValue: 'E', noDefOption: true, smallMargin: true},
        {name: 'row_index', serverName: 'row_index', label: ''},
        {name: 'atype_id', serverName: 'atype_id', label: ''},
        {name: 'description', serverName: 'description', label: 'Account Type'},
        {name: 'unit', serverName: 'unit', label: 'Unit', options: unitOptions},
        {name: 'price', serverName: 'price', label: 'Price'},
        {name: 'remark', serverName: 'remark', label: 'Remark'},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }

  setData(data) {
    this.id = data.id;
    this.data = data;
    this.items = [];
  };

  getItems() {
    return this.items;
  };

  onRender = () => {
    const [gridMode, setGridMode] = React.useState('add');
    const [isScrollToBottom, setIsScrollToBottom] = React.useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = React.useState(undefined);
    const [selectedCurrency, setSelectedCurrency] = React.useState('CAD');
    const {_r, _f, _v, _n, _c, formik, fields, errors} = this.getRenderProps();
    const user = useUser();
    _f('branch_id').options = api.useAreaOptions(true);
    _f('branch_id').defaultValue = user.branch_id;
    _f(FN.CURRENCY).options = currencyOptions;
    _f(FN.CURRENCY).label = 'Currency';
    _f(FN.SUBJECT).required = true;
    _f(FN.ACC_TYPE).options = [...accTypeOptions, {value: 'QT', label: 'QT'}];

    const onAction = (action, data) => {
      if (action === 'delete') {
        onDelete(data);
      }
    };

    const onCellClicked = (e) => {
      _c('atype_id', e.data.atype_id);
      _c('description', e.data.description);
      _c('unit', e.data.unit);
      _c('currency', e.data.currency);
      _c('price', e.data.price);
      _c('remark', e.data.remark);
      setGridMode('edit');
      setSelectedRowIndex(e.data.row_index);
    };

    const grid = useItemGrid(onAction, onCellClicked, isScrollToBottom);
    this.grid = grid;

    const _at2 = (name) => <AccountTypeSearchInput
      idField={_n(`atype_id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
      onOK={(data) => {
        const {id, value, currency} = data;
        _c('atype_id', id);
        _c('description', value);
        setSelectedCurrency(currency);
      }}
    />;

    const _tpa = (name, partner, vertical = true) => {
      _f(name).smallMargin = name !== FN.PARTNER ? true : undefined;
      return (
        <TradePartnerSearchInput
          idField={_n(`${partner}_name`)} displayField={_n(name)}
          formik={formik} fields={fields} errors={errors} vertical={vertical} onOK={(data) => onTPartner(partner, data)}
        />
      );
    };

    const onTPartner = (name, data) => {
      const {id, value} = data;
      _c(`${name}_id`, id);
      _c(`${name}_name`, value);
    };

    const onSearch = () => {
      if (this.id) {
        apiForwarding.getARAPItem({id: this.id}).then((res) => {
          if (res) {
            const rows = res.data?.map((data, index) => ({...data, row_index: index}));
            grid.setRows(rows);
            this.items = rows;
          }
        });
      }
    };

    const onAdd = () => {
      const rows = this.grid.rows ?? [];
      let {atype_id, description, unit, price = 0, remark} = this.getValues();
      if (atype_id < 0 || !description) {
        return util.showWarning('Please enter a valid Account Type data!');
      }

      const newRow = {atype_id, description, unit, currency: selectedCurrency, price: util.toFloat(price), remark};
      let newRows;
      if(gridMode === 'add') {
        newRows = [...rows, newRow].map((data, index) => ({...data, row_index: index}));
      } else if(gridMode === 'edit') {
        const rows = grid.rows;
        const rowIndex = rows.findIndex(i => i.row_index === selectedRowIndex);
        newRows = [...rows];
        newRows[rowIndex] = newRow;
      }
      grid.setRows(newRows);
      setIsScrollToBottom(gridMode === 'add');
      setGridMode('add');
      this.items = newRows;
      this.setFocus('description');
      resetField();
    };

    const onDelete = (data) => {
      util.showConfirm('Are you sure to delete?', async () => {
        const rows = [];
        if (grid.apiRef && grid.apiRef.current) {
          grid.apiRef.current.forEachNode(node => rows.push(node.data));
        }
        const newRows = rows.filter(x => x.row_index !== data.row_index);
        grid.setRows(newRows);
        this.items = newRows;
        resetField();
      });
    };

    const resetField = () => {
      _c('atype_id', undefined);
      _c('description', undefined);
      _c('unit', unitOptions[0].value);
      _c('price', undefined);
      _c('remark', undefined);
      setSelectedRowIndex(undefined);
    };

    const onCancelEdit = () => {
      setGridMode('add');
      setSelectedRowIndex(undefined);
      this.clearValues();
    };

    React.useEffect(() => {
      this.setValues(this.data);
      onSearch();
    }, []);

    React.useEffect(() => {
      if (!this.id) {
        _c('branch_id', user.branch_id);
        _c(FN.ACC_TYPE, accTypeOptions[0].value);
        _c(FN.CURRENCY, currencyOptions[0].value);
        _c(FN.KIND, 'F');
      }
      _c('unit', unitOptions[0].value);
    }, [this.id]);

    return (
      <div className={'w-full'}>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex-1 mr-2'}>{_r(FN.SUBJECT)}</div>
          <div className={'flex-1 mr-2'}>{_r('branch_id')}</div>
          <div className={'flex-1 mr-2'}>{_r('bound')}</div>
        </div>
        <div className={'flex between w-full mb-2'}>
          <div className={'flex flex-1 w-full mr-2'}>
            <div className={'flex-1'}>{_r(FN.ACC_TYPE)}</div>
            <div className={'flex-1'}>{_r(FN.CURRENCY)}</div>
          </div>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_tpa(FN.PARTNER_NAME, 'partner')}</div>
        </div>
        {grid.render()}
        <div className={'flex w-full'}>
          <div className={'flex-3'}>{_at2('description')}</div>
          <div className={'flex-1'}>{_r('unit')}</div>
          <div className={'flex-1'}>{_r('price')}</div>
          {gridMode === 'add' ?
            <div className={'ml-20'}><DefaultButton label={'Add'} onClick={onAdd} noMargin /></div> :
            (
              <div className={'ml-20'}>
                <DefaultButton label={'Edit'} onClick={onAdd} />
                <DefaultButton label={'Cancel'} onClick={onCancelEdit} />
              </div>
            )
          }
        </div>
        <div className={'flex-1'}>{_r('remark')}</div>
      </div>
    );
  };

  onValidate = (values) => {return values};
  getRenderProps = (grid) => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, , fields, errors] = this.renderParams;
    return {_r, _f, _v, _n, _c, formik, fields, errors, grid, fm: this};
  };
}

function useItemGrid(onAction, onCellClicked, isScrollToBottom) {
  function getColumns(): ColDef[] {
    return [
      {field: 'description', headerName: 'Account Type', rowDrag: true, flex: 2},
      {field: 'unit', headerName: 'Unit', flex: 1},
      {field: 'currency', headerName: 'Currency', flex: 1},
      {field: 'price', headerName: 'Price', flex: 1},
      {field: 'remark', headerName: 'Remark', flex: 3},
    ];
  }
  const grid = useSimpleGrid({
    columns: getColumns(),
    height: 300,
    className: 'pb-20',
    actions: ['delete'],
    actionWidth: 70,
    onAction: onAction,
    isScrollToBottom,
    agGridProps: {
      suppressRowClickSelection: true,
      rowDragManaged: true,
      animateRows: true,
      onCellClicked(e) {
        if(e.colDef.headerName !== 'Actions') {
          onCellClicked(e);
        }
      },
      onRowDragEnd(e) {
        const rows = grid.rows;
        const id = e.node.data['row_index'];
        const newIndex = e.node.rowIndex;
        const sourceIndex = rows.findIndex(i => i.row_index === id);
        const sourceRow = rows[sourceIndex];
        rows.splice(sourceIndex, 1);
        rows.splice(newIndex, 0, sourceRow);
        grid.setRows(rows);
      },
    }
  });
  return grid;
}

export default ForwardingSettingAccountTypeFormManager;
