/**
 * @flow
 */
import React from "react";
import {util} from '../service';
import API from "./API";

class APICourier extends API {
  async setCourierBL(data) {
    const token = util.getToken();
    const action = 'SetCourierBL';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getTapeList() {
    const token = util.getToken();
    const action = 'GetTapeList';
    const reqData = {action, token, data: {}};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  useTapeOptions() {
    const [options, setOptions] = React.useState([]);
    React.useState(() => {
      this.getTapeList().then(({data}) => {
        const newOptions = data.map((data) => ({label: data, value: data}));
        setOptions([...newOptions]);
      }).catch();
    }, []);
    return options;
  }
  async getBLData(data) {
    const token = util.getToken();
    const action = 'GetBLData';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setWorkOrder(data) {
    const token = util.getToken();
    const action = 'SetWorkOrder';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async delWorkOrder(data) {
    const token = util.getToken();
    const action = 'DelWorkOrder';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async setAR(data) {
    const token = util.getToken();
    const action = 'SetAR';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async setAP(data) {
    const token = util.getToken();
    const action = 'SetAP';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async setAccountInfo(data) {
    const token = util.getToken();
    const action = 'SetAccountInfo';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async delAccountItem(data) {
    const token = util.getToken();
    const action = 'DelAccountItem';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async setAccountItem(data) {
    const token = util.getToken();
    const action = 'SetAccountItem';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getAccountItems(data) {
    const token = util.getToken();
    const action = 'GetAccountItems';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getAccountData(data) {
    const token = util.getToken();
    const action = 'GetAccountData';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setContainer(data) {
    const token = util.getToken();
    const action = 'SetContainer';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getMBLHouses(data) {
    const token = util.getToken();
    const action = 'GetMBLHouses';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getBulkHouse(data) {
    const token = util.getToken();
    const action = 'GetBulkHouse';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setMBLHouses(data) {
    const token = util.getToken();
    const action = 'SetMBLHouses';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getARAPItemList(data) {
    const token = util.getToken();
    const action = 'GetARAPItemList';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getARAPItem(data) {
    const token = util.getToken();
    const action = 'GetARAPItem';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setARAPItems(data) {
    const token = util.getToken();
    const action = 'SetARAPItems';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async delARAPItems(data) {
    const token = util.getToken();
    const action = 'DelARAPItems';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getHBlList(data) {
    const token = util.getToken();
    const action = 'GetHBlList';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getMBlList(data) {
    const token = util.getToken();
    const action = 'GetMBlList';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getStorageList(data) {
    const token = util.getToken();
    const action = 'GetStorageList';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getUpcommingList(data) {
    const token = util.getToken();
    const action = 'GetUpcommingList';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setPackingList(data) {
    const token = util.getToken();
    const action = 'SetPackingList';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getPackingList(data) {
    const token = util.getToken();
    const action = 'GetPackingList';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getComingMBL() {
    const token = util.getToken();
    const action = 'GetComingMBL';
    const reqData = {action, token, data: {}};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getCraftData(data) {
    const token = util.getToken();
    const action = 'GetCraftData';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setBLFlag(data) {
    const token = util.getToken();
    const action = 'SetBLFlag';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getCourierPrice(data) {
    const token = util.getToken();
    const action = 'GetCourierPrice';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getCLPHouses(data) {
    const token = util.getToken();
    const action = 'GetCLPHouses';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getCLPWaitingHouses(data) {
    const token = util.getToken();
    const action = 'GetCLPWaitingHouses';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async setCLPHouses(data) {
    const token = util.getToken();
    const action = 'SetCLPHouses';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async delCLPHouses(data) {
    const token = util.getToken();
    const action = 'DelCLPHouses';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async setCLPRemark(data) {
    const token = util.getToken();
    const action = 'SetCLPRemark';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async setCLPWOrder(data) {
    const token = util.getToken();
    const action = 'SetCLPWOrder';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async copyMBL(data) {
    const token = util.getToken();
    const action = 'CopyMBL';
    const reqData = {action, token, data};
    return await API.send('courier/CUD', reqData).then((res) => res);
  }
  async getPLBulkPrint(data) {
    const token = util.getToken();
    const action = 'GetPLBulkPrint';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getCLPPrintData(data) {
    const token = util.getToken();
    const action = 'GetCLPPrintData';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
  async getReceiptPrint(data) {
    const token = util.getToken();
    const action = 'GetReceiptPrint';
    const reqData = {action, token, data};
    return await API.send('courier/R', reqData).then((res) => res);
  }
}

export default APICourier;
