/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../../lib/FormManager';
import {api, util} from '../../../../services/service';
import {setBlStatus, setCarrierType, setKind} from "../../../../redux/reducers/newCoshipReducer";
import {FD, FN} from '../../../field-defs/newCoship';

class ForwardingMasterBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `forwarding-master-form`,
      fields: [
        FD.MBL_NO, FD.BOOKING_NO, FD.PORT_TYPE, FD.BRANCH_ID,
        {...FD.KIND, defaultValue: 'F'}, FD.TRADE_TYPE, FD.BL_STATUS,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const kind = _v(FN.KIND);
    const carrierType = _v(FN.PORT_TYPE);
    const blStatus = _v(FN.BL_STATUS);
    _f(FN.BRANCH_ID).options = api.useAreaOptions(true);
    _f(FN.BL_STATUS).onChange = (_, value) => onChangeBlStatus(value);

    const onChangeBlStatus = (value) => {
      _c(FN.BL_STATUS, value);
    };

    React.useEffect(() => {
      if (kind) {
        setKind(util.dispatch, kind);
      } else {
        _c(FN.KIND, 'F');
        setKind(util.dispatch, 'F');
      }
    }, [kind]);

    React.useEffect(() => {
      if (carrierType) {
        setCarrierType(util.dispatch, carrierType);
      } else {
        _c(FN.CARRIER_TYPE, 'O');
        setCarrierType(util.dispatch, 'O');
      }
    }, [carrierType]);

    React.useEffect(() => {
      if (blStatus) {
        setBlStatus(util.dispatch, blStatus);
      } else {
        _c(FN.BL_STATUS, 'NG');
        setBlStatus(util.dispatch, 'NG');
      }
    }, [blStatus]);

    return(
      <div style={{width: 'calc(100% + 8px)', marginTop: 30}}>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BOOKING_NO)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.BL_STATUS)}</div>
          <div className={'flex-1 mr-2'}>{''}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default ForwardingMasterBLFormManager;
