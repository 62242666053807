/**
 * @flow
 */
import React from "react";
import FormManager from "../../../../lib/FormManager";
import {util} from "../../../../services/service";
import Icon from "mdi-react/SearchIcon";
import {blStatusOptions} from "../../../field-defs/newCoship";

export const SEARCH = 'qryText';
export const FORWARDING_FROM_DATE = 'fromDate';
export const FORWARDING_TO_DATE = 'toDate';
export const FORWARDING_BL_STATUS = 'bl_status';

export default class ForwardingBLListSearchFormManager extends FormManager {
  onSearch: () => void;
  clickedIndex;
  constructor() {
    super({
      prefix: 'forwarding-bl-list-container-search',
      fields: [
        {name: SEARCH, serverName: SEARCH, label: 'Search', smallMargin: true},
        {name: FORWARDING_FROM_DATE, serverName: FORWARDING_FROM_DATE, label: 'ETA', smallLabel: true, smallMargin: true, type: 'date'},
        {name: FORWARDING_TO_DATE, serverName: FORWARDING_TO_DATE, label: '', smallLabel: true, smallMargin: true, type: 'date'},
        {name: FORWARDING_BL_STATUS, serverName: FORWARDING_BL_STATUS, label: 'B/L Status', options: [{value: '', label: 'ALL'}, ...blStatusOptions], noDefOption: true, smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    _f(SEARCH).onEnter = this.onSearch;

    const blStatus = _v(FORWARDING_BL_STATUS);

    const onClickSearch = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onSearch();
    };

    const _sh = () => {
      return (
        <div className={`flex middle`}>
          {_r(SEARCH)}
          <div className={'ml-8 mt-16'}>
            <a href={'/#search'} onClick={onClickSearch}>
              <Icon size={20} />
            </a>
          </div>
        </div>
      );
    };

    React.useEffect(() => {
      const fromDate = util.toTS(util.getSubtractDays(14, util.getCurrentDate()));
      const toDate = util.toTS(util.getAddMonths(3, fromDate));
      _c(FORWARDING_FROM_DATE, fromDate);
      _c(FORWARDING_TO_DATE, toDate);
    }, []);

    React.useEffect(() => {
      this.clickedIndex === 3 && this.onSearch();
    }, [blStatus, this.clickedIndex]);

    return (
      <>
        {_sh()}
        {_r(FORWARDING_FROM_DATE)}
        <div>~</div>
        {_r(FORWARDING_TO_DATE)}
        {_r(FORWARDING_BL_STATUS)}
      </>
    );
  };
}
