/**
 * @flow
 */
import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {useTopbar} from "../../redux/reducers/topbarReducer";
import {useUser} from "../../redux/reducers/userReducer";
import {useParams} from "react-router-dom";
import moment from "moment";
import {api, apiBL, util} from "../../services/service";
import {kindOptions} from "../../forms/field-defs/bl";
import Select from 'react-select';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {useDayScheduleModal} from "../../modals/Schedule/useDayScheduleModal";
import {useDayScheduleAddModal} from "../../modals/Schedule/useDayScheduleAddModal";
import {useDaySchedulePreviewModal} from "../../print-layouts/DaySchedulePrint";
import { Tooltip } from "bootstrap";

let tooltipInstance = null;

const BLFullSchedule = () => {
  useTopbar({label: 'Schedule'}, {label: 'Schedule: Full'});
  const user = useUser();
  const params = useParams();
  const kind = !user.team_main || (user.team_main === 'N' || user.team_main === 'A' || user.team_main === 'S' || user.team_main === 'F') ? [] : [user.team_main];
  const [events, setEvents] = React.useState([]);
  const [service, setService] = React.useState(kind);
  const [branch, setBranch] = React.useState(user.branch_id);
  const [searchValue, setSearchValue] = React.useState('');
  const [scheduleDataList, setScheduleDataList] = React.useState('');
  const viewRef = React.useRef();
  const fromRef = React.useRef();
  const toRef = React.useRef();
  const dateRef = React.useRef();
  const kindRef = React.useRef(kind);
  const branchRef = React.useRef();
  const scheduleKindOptions = [...kindOptions];
  const areaOptions = api.useAreaOptions(true);
  const branchId = branch ?? user.branch_id;
  const initialDate = moment().format('YYYYMMDD');
  const dayScheduleModal = useDayScheduleModal();
  const dayScheduleAddModal = useDayScheduleAddModal();
  const daySchedulePrintModal = useDaySchedulePreviewModal();

  async function loadSchedule(from, to, view, searchText) {
    if (branchRef.current || branchRef.current === 0) {
      if (branchRef.current === undefined) {
        branchRef.current = user.branch_id;
      }
      if (!kindRef.current) {
        kindRef.current = kind;
      }
      const qryText = searchText || undefined;
      from = from ?? fromRef.current;
      to = to ?? toRef.current;
      if (!(from && to)) {
        const [fromNow, toNow] = util.getBeginEndDateTS(dateRef.current, view ?? viewRef.current ?? 'month');
        from = fromNow;
        to = toNow;
      }
      const {data} = await apiBL.calendarList({
        fromDate: from,
        toDate: to,
        branchId: branchRef.current,
        kindArr: kindRef.current,
        qryText,
      });
      setScheduleDataList(data);
      if (Array.isArray(data)) {
        const newEvents = data.map(row => {
          const {
            id,
            balance,
            bl_id: blId,
            bl_no: blNo,
            cbm = 0,
            workplace: name,
            city,
            work_date: date,
            work_time: time,
            work_type: workType,
            work_count: workCount,
            h_m: hm,
            in_out,
            filing_no,
          } = row;
          let title = `${in_out} ${cbm ?? ''} ${name} ${time ? time : ''} ${city ? city : ''}`;
          if (workCount > 1) {
            title += `(${workCount})`;
          }
          const tColor = balance > 0 && !filing_no ? 'red' : balance === 0 && !filing_no ? 'green' : balance > 0 && filing_no ? 'orange' : 'black';
          return {
            title,
            start: moment(date).toDate(),
            end: moment(date).toDate(),
            allDay: true,
            resource: {id, blId, blNo, cbm, name, city, date, time, workType, workCount, hm, balance},
            url: '/admin/schedule/workorder/set',
            textColor: tColor,
            color: date === util.getCurrentDate() ? '#fffadf' : 'white',
          };
        });
        setEvents(newEvents);
      }
    }
  }
  const onKindChange = (e) => {
    setService(Array.isArray(e)?e.map(x=>x.value):[])
    kindRef.current = Array.isArray(e)?e.map(x=>x.value):[];
    loadSchedule(fromRef.current, toRef.current, 'month', searchValue).catch();
  };
  const onBranchChange = ({target: {value}}) => {
    setBranch(Number(value));
    branchRef.current = Number(value);
    loadSchedule(fromRef.current, toRef.current, 'month', searchValue).catch();
  };
  const onKeyDown = (e) => {
    const {target: {value}, key} = e;
    if (key === 'Enter') {
      setSearchValue(value);
      loadSchedule(fromRef.current, toRef.current, 'month', value).catch();
    }
  };
  const onSearch = (e, mode) => {
    e.preventDefault();
    const el = document.querySelector('#bl-schedule-search');
    const {value} = el || undefined;
    setSearchValue(value);
    mode === 'search' && loadSchedule(fromRef.current, toRef.current, 'month', value).catch();
  };
  const onDateClick = (date) => {
    const selectedDataList = scheduleDataList.filter(x => x.work_date === util.formatD(date));
    if (selectedDataList?.length > 0) {
      dayScheduleModal.open(selectedDataList, date, daySchedulePrintModal, dayScheduleAddModal);
    }
  };
  const eventMouseEnter = (info) => {
    const title = `
      <div>${info.event._def.title}</div>
    `;
    if (info.event._def.title) {
      tooltipInstance = new Tooltip(info.el, {
        title,
        html: true,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
      });
      tooltipInstance.show();
    }
  };
  const eventMouseLeave = () => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  React.useEffect(() => {
    if (user.id > 0) {
      branchRef.current = user.branch_id;
      loadSchedule(undefined, undefined, 'month', searchValue).catch();
    }
  }, [user.id, params.ts]);

  return (
    <div className={'calendar'} style={{position: 'relative', fontWeight: 'bold'}}>
      <div className={'form flex '}>
        <div className={'form__form-group flex-1'} style={{zIndex: 3}}>
          <Select isMulti defaultValue={scheduleKindOptions.find(x=>x.value===kind[0])} options={scheduleKindOptions} onChange={onKindChange} />
        </div>
        <div className={'form__form-group flex-1'} style={{marginLeft: '20px',zIndex: 3}}>
          <select value={branchId} onChange={onBranchChange} style={{height: 46, borderRadius: 4}}>
            {areaOptions.map(({value, label}) => {
              return <option key={value} value={value}>{label}</option>
            })}
          </select>
        </div>
        <div className={'form__form-group flex-1'} style={{marginLeft: '20px',zIndex: 3}}>
          <div className={'form__form-group flex ml-1'}>
            <input name={'qryText'} id={'bl-schedule-search'} placeholder={'Search'} onChange={(e) => onSearch(e, 'change')} onKeyUp={onKeyDown} style={{height: 46}}/>
            <a href={'/#search'} onClick={(e) => onSearch(e, 'search')} className={'inbox-search-icon'} style={{width: 40, height: 46}}><MagnifyIcon style={{width: 30, height: 46}}/></a>
          </div>
        </div>
      </div>
      <div style={{zIndex: 2}}>
        <FullCalendar
          plugins={[ dayGridPlugin ]}
          headerToolbar={{
            left: 'today prevYear,prev,next,nextYear',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek'
          }}
          initialView='dayGridMonth'
          initialDate={initialDate}
          navLinks={true}
          navLinkDayClick={onDateClick}
          showNonCurrentDates={false}
          fixedWeekCount={false}
          eventTextColor={'#666'}
          contentHeight={'auto'}
          events={events}
          eventMouseEnter={eventMouseEnter}
          eventMouseLeave={eventMouseLeave}
          eventClick={(data) => {
            data.jsEvent.preventDefault();
            const {resource: {id, blNo}} = data?.event?._def?.extendedProps;
            if (id && blNo) {
              util.openTab(`/admin/schedule/workorder/set?id=${id}&blNo=${blNo}`);
            }
          }}
          datesSet={(date) => {
            fromRef.current = util.toTS(date.start);
            toRef.current = util.toTS(date.end);
            dateRef.current = date.start;
            loadSchedule(util.toTS(date.start), util.toTS(date.end), 'month', searchValue).catch();
          }}
        />
      </div>
      {dayScheduleModal.render()}
      {dayScheduleAddModal.render()}
      {daySchedulePrintModal.render()}
    </div>
  )
}

export default BLFullSchedule;
