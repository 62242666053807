/**
 * @flow
 */
import React from 'react';
import {useSimpleModal} from '../../../components/SimpleModal';
import {apiAccount, util} from "../../../services/service";
import {apiForwarding} from "../../../services/newCoship/service";
import ForwardingQuoteFormManager from "../../../forms/newCoship/forwarding/quote/ForwardingQuoteFormManager";

const editForm = new ForwardingQuoteFormManager();

export function useForwardingQuoteModal(onSearch, quotationPrintModal) {
  const [data, setData] = React.useState(undefined);

  const onSave = () => {
    const values = editForm.getValues();
    if (!values.partner_name || !values.qt_date || !values.valid_until) {
      return util.showWarning('Please enter required field!');
    }
    const items = editForm.getItems();
    const newItems = items?.map((data) => (
      {atype_id: data.atype_id, job_id: data.job_id, rate: data.rate, volume: data.volume, unit: data.unit, cur_rate: data.cur_rate, remark: data.remark}
    ));
    let currencyRates;
      if (values.rate_currency1 && values.rate_value1 && !(values.rate_currency2 && values.rate_value2)) {
        currencyRates = [{currency: values.rate_currency1, cur_rate: util.toFloat(values.rate_value1)}];
      } else if (!(values.rate_currency1 && values.rate_value1) && values.rate_currency2 && values.rate_value2) {
        currencyRates = [{currency: values.rate_currency2, cur_rate: util.toFloat(values.rate_value2)}];
      } else if (values.rate_currency1 && values.rate_value1 && values.rate_currency2 && values.rate_value2) {
        currencyRates = [
          {currency: values.rate_currency1, cur_rate: util.toFloat(values.rate_value1)},
          {currency: values.rate_currency2, cur_rate: util.toFloat(values.rate_value2)},
        ]
      } else {
        currencyRates = [];
      }
    const payload = {
      id: data?.id ?? undefined,
      partner_id: values.partner_id,
      partner_name: values.partner_name,
      branch_id: util.toInt(values.branch_id),
      qt_date: values.qt_date,
      valid_until: values.valid_until,
      term: values.term,
      pol: values.pol,
      pod: values.pod,
      destination: values.destination,
      volume_cbm: values.volume_cbm,
      bank_info: values.bank_info,
      priority: values.priority,
      memo: values.memo,
      main_currency: values.currency,
      currency_rates: currencyRates,
      items: newItems ?? [],
    };
    apiForwarding.setQuotation(payload).then((res) => {
      if (res) {
        util.showSuccess('Qutation has been saved successfully!');
        onSearch();
        onClose();
      }
    });
  };

  const onPrint = () => {
    const branchId = editForm.getValue('branch_id');
    const bankInfo = editForm.getValue('bank_info');
    const items = editForm.getItems();
    apiAccount.branchList().then(res => {
      apiAccount.bankInfo(branchId, bankInfo).then(response => {
        quotationPrintModal.open(editForm, items, res.data.lists, response.data[0], data.qt_no);
      });
    });
  };

  const onClose = () => {
    modal.close();
  };

  const getButtons = () => {
    if (data?.id) {
      return [
        {label: 'Save', color: 'primary', onClick: onSave},
        {label: 'Print', color: 'primary', onClick: onPrint},
      ];
    } else {
      return [
        {label: 'Save', color: 'primary', onClick: onSave},
      ];
    }
  };

  const modal = useSimpleModal({
    title: `Quotation Add / Edit`,
    centered: true,
    width: 1000,
    buttons: getButtons(),
    children: editForm.renderForm(),
  });

  const open = (data) => {
    setData(data);
    editForm.setData(data);
    modal.open();
  };

  return {...modal, open};
}
